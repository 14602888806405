import { createStore, applyMiddleware, compose } from 'redux';
import promise from 'redux-promise';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { reduxFirestore, getFirestore } from 'redux-firestore';
import { reactReduxFirebase, getFirebase } from 'react-redux-firebase'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'



import RootReducer from './reducers';
import fbConfig from './components/config/fbconfig';

const middleware = applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore }), promise, logger);
// const middleware = applyMiddleware(thunk, promise, logger);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
    key: 'root',
    storage,
  }
  
  const persistedReducer = persistReducer(persistConfig, RootReducer)

let Store = createStore(
        persistedReducer,
        composeEnhancers(
            middleware,
            reduxFirestore(fbConfig),
            reactReduxFirebase(fbConfig, {useFirestoreForProfile: true, attachAuthIsReady: true})
        )
);
let persistor = persistStore(Store)

export { Store, persistor };