import { 
    LOAD_RESOURCES,
    LOAD_RESOURCES_SUCCESS,
    LOAD_RESOURCES_FAIL,
    ADDING_RESOURCE,
    ADDING_RESOURCE_SUCCESS,
    ADDING_RESOURCE_FAIL,
    // UPDATEING_RESOURCE,
    // UPDATEING_RESOURCE_SUCCESS,
    // UPDATEING_RESOURCE_FAIL
} from '../utils/ActionTypes';

const initialState = {
    data: [],
    isFetching: false,
    hasError: false,
    errorMessage: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD_RESOURCES:
            return {
                ...state,
                isFetching: true,
            }
            
        case LOAD_RESOURCES_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: action.payload
            }

        case LOAD_RESOURCES_FAIL:
            return {
                ...state,
                isFetching: false,
                hasError: true,
                errorMessage: action.payload
            }
        case ADDING_RESOURCE:
            return {
                ...state,
                isFetching: true,
            }

        case ADDING_RESOURCE_SUCCESS:
            return {
                ...state,
                isFetching: false,
            }

        case ADDING_RESOURCE_FAIL:
            return {
                ...state,
                isFetching: false,
                hasError: true,
                errorMessage: action.payload
            }

        // case UPDATEING_RESOURCE:
        //     return {
        //         ...state,
        //     }

        // case UPDATEING_RESOURCE_SUCCESS:
        //     return {
        //         ...state,
        //     }

        // case UPDATEING_RESOURCE_FAIL:
        //     return {
        //         ...state,
        //     }
    
        default:
            return state;
    }
}
