import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';

import AuthReducer  from './AuthReducer';
import PageReducer from './PageReducer';
import TeamMemberReducer from './TeamMemberReducer';
import AddMemberReducer from './AddMemberReducer';
import ServiceReducer from './ServiceReducer';
import ResourceReducer from './ResourceReducer';
import SliderReducer from './SliderReducer';

export default combineReducers({
    auth: AuthReducer,
    pages: PageReducer,
    teams: TeamMemberReducer,
    addNewMember: AddMemberReducer,
    services: ServiceReducer,
    resources: ResourceReducer,
    sliders: SliderReducer,
    firestore: firestoreReducer,
    firebase: firebaseReducer,
});