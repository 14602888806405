import { AUTH, AUTH_SUCCESS,  AUTH_FAIL, SIGNOUT_SUCCESS  } from '../utils/ActionTypes';

const initialState = {
    isFetching: null,
    hasError: false,
    errorMessage: null
};

export default function(state=initialState, action){
    switch(action.type){
        case AUTH:
            return Object.assign({}, state, {
                isFetching: true,
                hasError: false,
                errorMessage: null
            });
        case AUTH_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                hasError: false,
                errorMessage: false
            });

        case AUTH_FAIL:
            return Object.assign({}, state, {
                isFetching: false,
                hasError: action.payload,
                errorMessage: action.err
            });
        
        case SIGNOUT_SUCCESS:
            return state;

        default:
            return state;
    }
}