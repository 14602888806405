import { 
    LOAD_PAGE_LIST, 
    LOAD_PAGE_LIST_SUCCESS, 
    LOAD_PAGE_LIST_FAIL,
    UPDATEING_PAGE,
    UPDATEING_PAGE_SUCCESS,
    UPDATEING_PAGE_FAIL  
} from '../utils/ActionTypes';

const initialState = {
    // page_name:"",
    // page_heading: "",
    // page_created_at: new Date(),
    // page_created_by: 'admin',
    data: [],
    isFetching: false,
    hasError: false,
    errorMessage: null
}

export default (state = initialState, action) => {
    switch(action.type) {
        case LOAD_PAGE_LIST:
            return {
                ...state,
                isFetching: true,
            }
        
        case LOAD_PAGE_LIST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                hasError: false,
                errorMessage: null,
                data: action.payload
            }
        
        case LOAD_PAGE_LIST_FAIL:
            return {
                ...state,
                data:[],
                isFetching: false,
                hasError: true,
                errorMessage: action.payload.message
            }
        case UPDATEING_PAGE:
            return {
                ...state,
                isFetching: true,
            }
        
        case UPDATEING_PAGE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                hasError: false,
                errorMessage: null
            }
        
        case UPDATEING_PAGE_FAIL:
            return {
                ...state,
                isFetching: false,
                hasError: true,
                errorMessage: action.payload             
            }
        
            default:
                return state;
    }
}