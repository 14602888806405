import { 
    LOAD_SLIDERS,
    LOAD_SLIDERS_SUCCESS,
    LOAD_SLIDERS_FAIL,
    ADDING_SLIDER,
    ADDING_SLIDER_SUCCESS,
    ADDING_SLIDER_FAIL,
    UPDATEING_SLIDER,
    UPDATEING_SLIDER_SUCCESS,
    UPDATEING_SLIDER_FAIL
} from '../utils/ActionTypes';

const initialState = {
    data: [],
    isFetching: false,
    hasError: false,
    errorMessage: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD_SLIDERS:
            return {
                ...state,
                isFetching: true,
            }
            
        case LOAD_SLIDERS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: action.payload
            }

        case LOAD_SLIDERS_FAIL:
            return {
                ...state,
                isFetching: false,
                hasError: true,
                errorMessage: action.payload
            }
        case ADDING_SLIDER:
            return {
                ...state,
                isFetching: true,
            }

        case ADDING_SLIDER_SUCCESS:
            return {
                ...state,
                isFetching: false,
            }

        case ADDING_SLIDER_FAIL:
            return {
                ...state,
                isFetching: false,
                hasError: true,
                errorMessage: action.payload
            }

        // case UPDATEING_SLIDER:
        //     return {
        //         ...state,
        //     }

        // case UPDATEING_SLIDER_SUCCESS:
        //     return {
        //         ...state,
        //     }

        // case UPDATEING_SLIDER_FAIL:
        //     return {
        //         ...state,
        //     }
    
        default:
            return state;
    }
}
