import { LOAD_TEAM_LIST, LOAD_TEAM_LIST_SUCCESS, LOAD_TEAM_LIST_FAIL } from "../utils/ActionTypes";

const initialState = {
    isFetching: false,
    data:[],
    hasError: false,
    errorMessage: null
}

export default function(state = initialState, action){
    switch (action.type) {
        case LOAD_TEAM_LIST:
            return Object.assign({}, state, {
                isFetching: true,
                hasError: false,
                errorMessage: null
            });

        case LOAD_TEAM_LIST_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                data: action.payload,
                hasError: false,
                errorMessage: null
            });
        
        case LOAD_TEAM_LIST_FAIL:
            return Object.assign({}, state, {
                isFetching: false,
                data:[],
                hasError: true,
                errorMessage: action.payload.message
            });
        default:
            return state;
    }
}