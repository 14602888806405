import { 
    LOAD_SERVICES, 
    LOAD_SERVICES_SUCCESS, 
    LOAD_SERVICES_FAIL, 
    ADDING_SERVICE, 
    ADDING_SERVICE_SUCCESS,
    ADDING_SERVICE_FAIL
} from '../utils/ActionTypes';

const initialState = {
    data: [],
    isFetching: false,
    hasError: false,
    errorMessage: null
}

export default (state = initialState, action) => {
    switch(action.type) {
        case LOAD_SERVICES:
            return {
                ...state,
                isFetching: true,
            }
        
        case LOAD_SERVICES_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: action.payload
            }
        
        case LOAD_SERVICES_FAIL:
            return {
                ...state,
                isFetching: false,
                hasError: true,
                errorMessage: action.payload.message
            }
        
        case ADDING_SERVICE:
            return {
                ...state,
                isAdding: true,
            }
        
        case ADDING_SERVICE_SUCCESS: 
            return {
                ...state,
                isAdding: false,
                data: action.payload
            }
        
        case ADDING_SERVICE_FAIL:
            return {
                ...state,
                isAdding: false,
                hasError: true,
                errorMessage: action.payload
            }
        
            default:
                return state;
    }
}