import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
// import App from './App';
import { Provider } from 'react-redux';
import 'semantic-ui-css/semantic.min.css';
import { Dimmer, Loader } from 'semantic-ui-react';
import { PersistGate } from 'redux-persist/integration/react'

import {Store, persistor} from './app/store';

const App = React.lazy(() => import('./App'));

const SiteLoader = <Dimmer active inverted><Loader size='large'>Loading...</Loader></Dimmer>

Store.firebaseAuthIsReady.then(() => {
    ReactDOM.render(
            <PersistGate loading={null} persistor={persistor}>
                <Provider store={Store}>
                    <Suspense fallback={SiteLoader}>
                        <App />
                    </Suspense>
                </Provider>
            </PersistGate>
        , document.getElementById('root'));
})

