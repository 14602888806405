import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

// Initialize Firebase
// var config = {
//     apiKey: process.env.REACT_APP_API_KEY,
//     authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//     databaseURL: process.env.REACT_APP_DATABASE_URL,
//     projectId: process.env.REACT_APP_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID
// };


var config = {
    apiKey: "AIzaSyCoNwYD12L-IMuS2XYwWTykv48nqiyHAJs",
    authDomain: "alia-87dd2.firebaseapp.com",
    databaseURL: "https://alia-87dd2.firebaseio.com",
    projectId: "alia-87dd2",
    storageBucket: "alia-87dd2.appspot.com",
    messagingSenderId: "57685454621"
}

// const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

firebase.initializeApp(config);
// firebase.firestore().settings({ timestampsInSnapshots: true });

export default firebase;