export const AUTH = "AUTH";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";

export const SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS";

export const LOAD_PAGE_LIST = "LOAD_PAGE_LIST";
export const LOAD_PAGE_LIST_SUCCESS = "LOAD_PAGE_LIST_SUCCESS";
export const LOAD_PAGE_LIST_FAIL = "LOAD_PAGE_LIST_FAIL";

export const UPDATEING_PAGE = "UPDATEING_PAGE";
export const UPDATEING_PAGE_SUCCESS = "UPDATEING_PAGE_SUCCESS";
export const UPDATEING_PAGE_FAIL = "UPDATEING_PAGE_FAIL";

export const LOAD_TEAM_LIST = "LOAD_TEAM_LIST";
export const LOAD_TEAM_LIST_SUCCESS = "LOAD_TEAM_LIST_SUCCESS";
export const LOAD_TEAM_LIST_FAIL = "LOAD_TEAM_LIST_FAIL";

export const ADDING_TEAM_MEMBER = "ADDING_TEAM_MEMBER";
export const ADDING_TEAM_MEMBER_SUCCESS = "ADDING_TEAM_MEMBER_SUCCESS";
export const ADDING_TEAM_MEMBER_FAIL = "ADDING_TEAM_MEMBER_FAIL";

export const UPDATEING_TEAM_MEMBER = "UPDATEING_TEAM_MEMBER";
export const UPDATEING_TEAM_MEMBER_SUCCESS = "UPDATEING_TEAM_MEMBER_SUCCESS";
export const UPDATEING_TEAM_MEMBER_FAIL = "UPDATEING_TEAM_MEMBER_FAIL";

export const LOAD_SERVICES = "LOAD_SERVICES";
export const LOAD_SERVICES_SUCCESS = "LOAD_SERVICES_SUCCESS";
export const LOAD_SERVICES_FAIL = "LOAD_SERVICES_FAIL";

export const ADDING_SERVICE = "ADDING_SERIVICE";
export const ADDING_SERVICE_SUCCESS = "ADDING_SERIVICE_SUCCESS";
export const ADDING_SERVICE_FAIL = "ADDING_SERIVICE_FAIL";

export const UPDATEING_SERVICE = "UPDATEING_SERVICE";
export const UPDATEING_SERVICE_SUCCESS = "UPDATEING_SERVICE_SUCCESS";
export const UPDATEING_SERVICE_FAIL = "UPDATEING_SERVICE_FAIL";

export const LOAD_RESOURCES = "LOAD_RESOURCES";
export const LOAD_RESOURCES_SUCCESS = "LOAD_RESOURCES_SUCCESS";
export const LOAD_RESOURCES_FAIL = "LOAD_RESOURCES_FAIL";

export const ADDING_RESOURCE = "ADDING_RESOURCE";
export const ADDING_RESOURCE_SUCCESS = "ADDING_RESOURCE_SUCCESS";
export const ADDING_RESOURCE_FAIL = "ADDING_RESOURCE_FAIL";

export const UPDATEING_RESOURCE = "UPDATEING_RESOURCE";
export const UPDATEING_RESOURCE_SUCCESS = "UPDATEING_RESOURCE_SUCCESS";
export const UPDATEING_RESOURCE_FAIL = "UPDATEING_RESOURCE_FAIL";

export const LOAD_SLIDERS = "LOAD_SLIDERS";
export const LOAD_SLIDERS_SUCCESS = "LOAD_SLIDERS_SUCCESS";
export const LOAD_SLIDERS_FAIL = "LOAD_SLIDERS_FAIL";

export const ADDING_SLIDER = "ADDING_SLIDER";
export const ADDING_SLIDER_SUCCESS = "ADDING_SLIDER_SUCCESS";
export const ADDING_SLIDER_FAIL = "ADDING_SLIDER_FAIL";

export const UPDATEING_SLIDER = "UPDATEING_SLIDER";
export const UPDATEING_SLIDER_SUCCESS = "UPDATEING_SLIDER_SUCCESS";
export const UPDATEING_SLIDER_FAIL = "UPDATEING_SLIDER_FAIL";