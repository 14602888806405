import { ADDING_TEAM_MEMBER, ADDING_TEAM_MEMBER_FAIL, ADDING_TEAM_MEMBER_SUCCESS } from '../utils/ActionTypes';

const initialState = {
    data:'',
    isFetching: false,
    hasError: false,
    errorMessage: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ADDING_TEAM_MEMBER:
            return {
                ...state,
                errorMessage: '',
                isFetching: true
            }
        case ADDING_TEAM_MEMBER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                errorMessage: 'Team Member Successfully Added',
                data: action.payload
            }
        case ADDING_TEAM_MEMBER_FAIL:
            return {
                ...state,
                hasError: true,
                errorMessage: action.payload
            }
    
        default:
            return state;
    }
}